import { createContext, useContext, useMemo, useState } from "react";
import { Stack } from "@mui/material";

import Toast from "../components/Toast";

const ToastContextDispatch = createContext();

export default function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  const openSuccessToast = (message) => {
    const toast = {
      isOpen: true,
      message,
      severity: "success",
    };
    setToasts([...toasts, toast]);
  };

  const openErrorToast = (message) => {
    const toast = {
      isOpen: true,
      message,
      severity: "error",
    };
    setToasts([...toasts, toast]);
  };

  const handleCloseAlert = (index) => {
    const newToasts = [...toasts];
    newToasts.splice(index, 1);
    setToasts(newToasts);
  };

  const dispatch = useMemo(
    () => ({
      openErrorToast,
      openSuccessToast,
    }),
    [openErrorToast, openSuccessToast]
  );

  return (
    <>
      <Stack
        sx={{
          zIndex: 100,
          left: "50%",
          position: "fixed",
        }}
      >
        {toasts.map((toast) => (
          <Stack spacing={2}>
            <Toast toast={toast} />
          </Stack>
        ))}
      </Stack>
      <ToastContextDispatch.Provider value={dispatch}>
        {children}
      </ToastContextDispatch.Provider>
    </>
  );
}

export const useToast = () => {
  const context = useContext(ToastContextDispatch);

  if (context === undefined) {
    throw new Error("use Toast must be used within a ToastProvider");
  }
  return context;
};
