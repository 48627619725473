import { useState, useEffect, createContext, useContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const AuthTokenContext = createContext();

const AuthProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [authToken, setAuthToken] = useState("");

  useEffect(() => {
    const getAuthToken = async () => {
      const token = await getAccessTokenSilently();
      setAuthToken(token);
    };
    getAuthToken();
  }, [getAccessTokenSilently]);

  const authState = useMemo(
    () => ({
      authToken,
    }),
    [authToken]
  );

  return (
    <AuthTokenContext.Provider value={authState}>
      {children}
    </AuthTokenContext.Provider>
  );
};

export default AuthProvider;
export const useAuthState = () => useContext(AuthTokenContext);
