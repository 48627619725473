import { Outlet } from "react-router-dom";
import { Container } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useUserState } from "./providers/UserStateProvider";
import AuthorizedTopNav from "./topNav/TopNav";
import OrganizationRequiredView from "./onboarding/OrgRequiredView";

export default function MainView() {
  const { userState } = useUserState();

  if (userState && !userState.organization_id) {
    return (
      <Container>
        <OrganizationRequiredView />
      </Container>
    );
  }

  return (
    <>
      <CssBaseline />
      <AuthorizedTopNav />
      <Container sx={{ minWidth: "100vw" }}>
        <Outlet />
      </Container>
    </>
  );
}

const BlankPage = () => {};
