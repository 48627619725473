import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  FindInPage,
  Summarize,
  Diversity2,
  LibraryAdd,
} from "@mui/icons-material";

import { useSearchDispatch } from "../../../../providers/SearchProvider";
import SegmentActionButton from "./SegmentActionButton";

export default function SegmentActionButtons({ result }) {
  const {
    handleSummarizeTextOpen,
    selectText,
    handleClipSegmentModalOpen,
    handleModalOpen,
    setScrollToPage,
  } = useSearchDispatch();

  const summarizeText = () => {
    handleSummarizeTextOpen(result.segment_text);
  };

  const addClipToFolio = () => {
    selectText(result);
    handleClipSegmentModalOpen();
    console.log("addClipToFolio");
  };

  return (
    <Grid container direction="row" spacing={0}>
      <SegmentActionButton
        icon={<Summarize />}
        action={summarizeText}
        title="Summarize"
      />
      <SegmentActionButton
        icon={<Diversity2 />}
        action={() => {
          console.log("TODO!");
        }}
        title="TODO"
      />
      <SegmentActionButton
        icon={<FindInPage />}
        action={() => {
          result.page_number
            ? setScrollToPage(result.page_number)
            : setScrollToPage(1);
          handleModalOpen(result.document_id);
        }}
        title="Find Document"
      />
      <SegmentActionButton
        icon={<LibraryAdd />}
        action={addClipToFolio}
        title="Add to Folio"
      />
    </Grid>
  );
}
