import { Box, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import {
  useSearchDispatch,
  useSearchState,
} from "../../../../providers/SearchProvider";
import { useDocumentQueryDispatch } from "../../../../providers/DocumentQueryProvider";
import SegmentActionButtons from "../segment-action-buttons";

export default function SourceSegmentView({ result }) {
  const { selectedText, searchResults } = useSearchState();
  const { selectText } = useSearchDispatch();
  const { openModalWithInitialSearchResults } = useDocumentQueryDispatch();

  const onClick = (result) => {
    selectText(result);
  };

  const handleSetModalState = () => {
    openModalWithInitialSearchResults({
      searchResults,
      documentId: result.document_id,
    });
  };

  const border =
    selectedText.segment_text === result.segment_text
      ? "2px solid #DCDCDC"
      : "";
  return (
    <Box border={border} p={1} borderRadius={1}>
      <Grid container>
        <Grid>
          <Typography onClick={() => onClick(result)}>
            {result.segment_text}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent={"space-between"}
          width="100%"
          alignItems={"center"}
        >
          <Grid>
            <Link component="button" onClick={handleSetModalState}>
              <Typography fontWeight="bold">
                {result.document_file_name}
              </Typography>
            </Link>
          </Grid>
          <Grid>
            <SegmentActionButtons result={result} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
