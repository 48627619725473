import { useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

export default function Toast({ toast }) {
  const [isOpen, setIsOpen] = useState(true);
  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen ? (
        <>
          <Snackbar
            open={isOpen}
            autoHideDuration={8000}
            onClose={(_event, reason) => {
              if (reason === "timeout") {
                handleClose();
              }
            }}
          />
          <Alert key={uuidv4()} onClose={handleClose} severity={toast.severity}>
            {toast.message}
          </Alert>
        </>
      ) : null}
    </>
  );
}
