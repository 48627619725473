import {
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { useMutation, useQuery } from "react-query";
import { track } from "@amplitude/analytics-browser";

import { useAuthState } from "./AuthProvider";
import {
  getDocumentDetails,
  queryDocument as queryDocumentService,
} from "../services/Documents";
import { generateFollowUpQuestions } from "../services/GenerationService";

const DocumentQueryProviderState = createContext();
const DocumentQueryProviderDispatch = createContext();

const ACTIONS = {
  OPEN_MODAL_WITH_SEARCH_RESULTS: "OPEN_MODAL_WITH_SEARCH_RESULTS",
  CLOSE_MODAL: "CLOSE_MODAL",
  OPEN_MODAL: "OPEN_MODAL",
  SET_SUGGESTED_QUERIES: "SET_SUGGESTED_QUERIES",
  SET_DOCUMENT_DETAILS: "SET_DOCUMENT_DETAILS",
  QUERY_DOCUMENT: "QUERY_DOCUMENT",
  SET_ANSWER: "SET_ANSWER",
};

const initialState = {
  modalOpen: false,
  pdfDocumentID: undefined,
  suggestedQueries: [],
  supportingEvidence: [],
  documentQuery: "",
  initialSearchResults: undefined,
  documentName: "",
  documentSummary: "",
  answer: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL_WITH_SEARCH_RESULTS:
      return {
        ...state,
        modalOpen: true,
        initialSearchResults: action.searchResults,
        pdfDocumentID: action.documentId,
      };
    case ACTIONS.OPEN_MODAL:
      return { ...state, modalOpen: true, pdfDocumentID: action.documentId };
    case ACTIONS.SET_DOCUMENT_DETAILS:
      return {
        ...state,
        documentName: action.documentName,
        documentSummary: action.documentSummary,
        suggestedQueries: action.suggestedQueries,
        documentQuery: action.documentQuery,
        answer: action.answer,
      };
    case ACTIONS.SET_SUGGESTED_QUERIES:
      return {
        ...state,
        suggestedQueries: action.suggestedQueries,
      };
    case ACTIONS.QUERY_DOCUMENT:
      return {
        ...state,
        suggestedQueries: [],
        documentQuery: action.documentQuery,
        initialSearchResults: undefined,
        answer: "",
      };
    case ACTIONS.SET_ANSWER:
      return {
        ...state,
        answer: action.answer,
        supportingEvidence: action.supportingEvidence,
      };
    case ACTIONS.CLOSE_MODAL:
      return initialState;
    default:
      return { ...state };
  }
};

export default function DocumentQueryProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    modalOpen,
    initialSearchResults,
    pdfDocumentID,
    documentSummary,
    suggestedQueries,
    documentQuery,
    answer,
  } = state;

  const { authToken } = useAuthState();

  const handleModalOpen = (documentId) => {
    dispatch({ type: ACTIONS.OPEN_MODAL, documentId });
    track("openDocument", { document_id: documentId });
  };

  const handleModalClose = () => {
    dispatch({ type: ACTIONS.CLOSE_MODAL });
  };

  const getNewSuggestedQueries = () => {
    generateQuestionsMutation({
      segments: [documentSummary],
      token: authToken,
    });
  };

  const { isLoading: qaLoading } = useQuery(
    ["document-qa", documentQuery, initialSearchResults],
    () => {
      const response = queryDocumentService(
        { id: pdfDocumentID, query: documentQuery },
        authToken
      );
      return response;
    },
    {
      enabled:
        !!authToken && !!documentQuery && !initialSearchResults && modalOpen,
      onSuccess: (res) => {
        console.log(res);
        dispatch({
          type: ACTIONS.SET_ANSWER,
          answer: res.answer,
          supportingEvidence: res.supporting_evidence,
        });
        generateQuestionsMutation({
          segments: [res.answer],
          query: res.query,
          token: authToken,
        });
      },
    }
  );

  const { data, isFetching: documentDetailsLoading } = useQuery(
    ["document-details", pdfDocumentID],
    () => {
      const response = getDocumentDetails({ id: pdfDocumentID }, authToken);
      return response;
    },
    {
      enabled: !!authToken && !!pdfDocumentID && modalOpen,
      onSuccess: (data) => {
        if (initialSearchResults) {
          dispatch({
            type: ACTIONS.SET_DOCUMENT_DETAILS,
            documentName: data.document_name,
            documentSummary: data.document_summary,
            suggestedQueries: data.suggested_queries,
            documentQuery: initialSearchResults.query,
            answer: initialSearchResults.generated_response,
          });
        } else {
          dispatch({
            type: ACTIONS.SET_DOCUMENT_DETAILS,
            documentName: data.document_name,
            documentSummary: data.document_summary,
            suggestedQueries,
            documentQuery,
            answer,
          });
          getNewSuggestedQueries();
        }
      },
    }
  );

  const {
    mutate: generateQuestionsMutation,
    isLoading: questionGenerationLoading,
  } = useMutation({
    mutationFn: async ({ segments, token, query }) => {
      const response = await generateFollowUpQuestions(segments, token, query);
      return response;
    },
    enabled: modalOpen,
    onSuccess: (response) => {
      dispatch({
        type: ACTIONS.SET_SUGGESTED_QUERIES,
        suggestedQueries: response.questions,
      });
    },
  });

  const questionsAnswersLoading = useMemo(() => {
    return questionGenerationLoading || qaLoading;
  }, [questionGenerationLoading, qaLoading]);

  const providerState = useMemo(() => {
    return {
      ...state,
      documentDetailsLoading,
      questionsAnswersLoading,
    };
  }, [state, documentDetailsLoading, questionsAnswersLoading]);

  const openModalWithInitialSearchResults = ({ searchResults, documentId }) => {
    dispatch({
      type: ACTIONS.OPEN_MODAL_WITH_SEARCH_RESULTS,
      searchResults,
      documentId,
    });
  };

  const queryDocument = ({ query }) => {
    dispatch({ type: ACTIONS.QUERY_DOCUMENT, documentQuery: query });
  };

  const providerDispatch = useMemo(() => {
    return {
      openModalWithInitialSearchResults,
      handleModalOpen,
      handleModalClose,
      queryDocument,
    };
  }, [
    openModalWithInitialSearchResults,
    handleModalOpen,
    handleModalClose,
    queryDocument,
  ]);

  return (
    <DocumentQueryProviderState.Provider value={providerState}>
      <DocumentQueryProviderDispatch.Provider value={providerDispatch}>
        {children}
      </DocumentQueryProviderDispatch.Provider>
    </DocumentQueryProviderState.Provider>
  );
}

export const useDocumentQueryState = () => {
  const context = useContext(DocumentQueryProviderState);
  if (context === undefined) {
    throw new Error(
      "useDocumentQueryState must be used within a DocumentQueryProvider"
    );
  }
  return context;
};
export const useDocumentQueryDispatch = () => {
  const context = useContext(DocumentQueryProviderDispatch);
  if (context === undefined) {
    throw new Error(
      "useDocumentQueryDispatch must be used within a DocumentQueryProvider"
    );
  }
  return context;
};
