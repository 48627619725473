import axios from "axios";
import { getFQDN } from "../utils/api";

export function CreateOrganization(
  organizationName,
  authToken,
  refetchUserState
) {
  axios
    .post(
      getFQDN("orgs"),
      {
        name: organizationName,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then(() => {
      refetchUserState();
    });
}

export async function getOrganization({ orgId }, authToken) {
  const response = await axios.get(`${getFQDN("orgs")}/${orgId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  });
  return response;
}
