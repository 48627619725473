import { createTheme } from "@mui/material/styles";

import grey from "@mui/material/colors/grey";
import amber from "@mui/material/colors/amber";

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: "#527EE7",
    },
    secondary: {
      main: grey[800],
    },
    warning: {
      main: amber[500],
    },
    background: {
      default: "#FAFAFA",
      light: grey[200],
      dark: grey[700],
    },
    border: {
      light: grey[300],
      dark: grey[600],
    },
  },
  typography: {
    allVariants: {
      fontFamily: ["Poppins"].join(","),
    },
  },
});

export default theme;
