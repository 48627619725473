import { useMemo } from "react";

import { Button, Typography, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import SearchResultsView from "./search/search-results";
import FolioView from "./FolioView";
import ClipView from "./ClipView";
import FeedbackView from "./FeedbackView";
import OrganizationRequiredView from "./onboarding/OrgRequiredView";
import ErrorComponent from "./errors/ErrorComponent";
import { folioLoader } from "./ClipView";
import MainView from "./MainView";
import SearchProvider from "./providers/SearchProvider";
import DocumentQueryProvider from "./providers/DocumentQueryProvider";
import ClipProvider from "./providers/ClipProvider";
import AuthProvider from "./providers/AuthProvider";
import UserStateProvider from "./providers/UserStateProvider";
import { init } from "@amplitude/analytics-browser";
import OrgProvider from "./providers/OrganizationProvider";

function LoginLandingPage() {
  const { loginWithRedirect } = useAuth0();
  return (
    <Grid
      container
      direction="column"
      justifyContent={"space-between"}
      alignItems={"center"}
      width="100vw"
      height="100vh"
      spacing={4}
    >
      <Grid>{""}</Grid>
      {/* this is an insane hack to create a spacer, forcing the footer to the bottom.  */}
      <Grid
        container
        direction="column"
        alignItems={"center"}
        justifyContent="center"
      >
        <Grid>
          <Typography variant="h2">The Carburetor Project</Typography>
        </Grid>
        <Grid>
          <Button variant="contained" onClick={() => loginWithRedirect()}>
            <Typography>Enter</Typography>
          </Button>
        </Grid>
        <Grid>
          <Typography variant="body2">
            They say the fox knows many things, while the hedgehog knows one big
            thing.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent={"flex-start"}
        width="100vw"
      >
        <Grid>
          <Typography variant="body2">
            An exploration by{" "}
            <Link href="https://uncommon.industries/">UncommonIndustries</Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainView />,
    errorElement: <ErrorComponent />,
    children: [
      { path: "", element: <SearchResultsView /> },
      { path: "/clips", element: <FolioView /> },
      { path: "/clips/:folioId", element: <ClipView /> },
      { path: "/feedback", element: <FeedbackView /> },
      { path: "/createOrganization", element: <OrganizationRequiredView /> },
    ],
  },
]);

function AuthenticatedRoutedView() {
  const { user } = useAuth0();
  init("e7ce8a3733cf0eaa1ccf9ebd5c24420d", user.sub);
  return (
    <AuthProvider>
      <UserStateProvider>
        <OrgProvider>
          <ClipProvider>
            <SearchProvider>
              <DocumentQueryProvider>
                <RouterProvider router={router} />
              </DocumentQueryProvider>
            </SearchProvider>
          </ClipProvider>
        </OrgProvider>
      </UserStateProvider>
    </AuthProvider>
  );
}

function App() {
  const { isAuthenticated, isLoading } = useAuth0();
  const memoizedIsAuthenticated = useMemo(
    () => isAuthenticated,
    [isAuthenticated]
  );
  if (isLoading) {
    return <div>Loading...</div>;
  }

  return memoizedIsAuthenticated !== null && memoizedIsAuthenticated ? (
    <AuthenticatedRoutedView />
  ) : (
    <LoginLandingPage />
  );
}

export default App;
