import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import fox from "./fox.svg";

export default function SearchAnswerCard({ answer }) {
  return (
    <Grid>
      <Box
        border={"3px solid #527EE7"}
        borderRadius={1}
        boxShadow={1}
        p={2}
        display="flex"
        alignItems="center"
      >
        <Box
          component="img"
          src={fox}
          sx={{
            width: 50,
            height: 50,
          }}
        />
        <Typography ml={2}>{answer}</Typography>
      </Box>
    </Grid>
  );
}
