import { Box, CircularProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { CloudUpload } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";

import { useDropzone } from "react-dropzone";

import {
  useSearchState,
  useSearchDispatch,
} from "../../providers/SearchProvider";
import { useDocumentQueryDispatch } from "../../providers/DocumentQueryProvider";

export default function OtherFilesView() {
  const { totalDocs, uploadDocumentLoading } = useSearchState();
  const { uploadDocument } = useSearchDispatch();

  const { handleModalOpen } = useDocumentQueryDispatch();

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      uploadDocument({ file });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Box
      height="100%"
      minHeight={"15vh"}
      {...getRootProps({ onClick: (event) => event.stopPropagation() })}
    >
      <Grid
        container
        direction="column"
        alignItems={"center"}
        sx={{ overScrollBehavior: "contain" }}
      >
        <input {...getInputProps()} />
        <Grid>
          <Typography fontSize={24} fontWeight={700}>
            Other Files
          </Typography>
        </Grid>
        {isDragActive ? (
          <Typography>Drop the files here ...</Typography>
        ) : (
          <OtherFilesList
            totalDocs={totalDocs}
            uploadDocumentLoading={uploadDocumentLoading}
            handleModalOpen={handleModalOpen}
          />
        )}
      </Grid>
    </Box>
  );
}

function OtherFilesList({ totalDocs, uploadDocumentLoading, handleModalOpen }) {
  const documentColorMap = {
    processing: "warning.main",
    processed: "primary.main",
    error: "palette.error.main",
  };

  return (
    <Grid container direction="column" p={0}>
      <Grid>
        {uploadDocumentLoading ? (
          <CircularProgress />
        ) : (
          <Box display="flex">
            <CloudUpload sx={{ color: "#527EE7" }} />

            <Typography ml={2}>"Drag Files here."</Typography>
          </Box>
        )}
      </Grid>
      <Grid>
        <Box sx={{ overflow: "scroll" }} maxHeight="40vh">
          {totalDocs.map((doc) => (
            <Box
              display="flex"
              key={doc.document_id}
              onClick={() => handleModalOpen(doc.document_id)}
              my={1}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              width="18rem"
            >
              <DescriptionIcon
                sx={{ color: documentColorMap[doc.document_state] }}
              />
              <Typography noWrap ml={2}>
                {doc.document_path}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}
