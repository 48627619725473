import { Box } from "@mui/material";

const PanelCard = ({ children, ...props }) => {
  return (
    <Box
      sx={{ border: "2px solid #DCDCDC", borderRadius: "5px" }}
      height="100%"
      backgroundColor={"white"}
      {...props}
    >
      {children}
    </Box>
  );
};

export default PanelCard;
