import axios from "axios";
import { getFQDN } from "../utils/api";


export async function generateFollowUpQuestions(segments,  token, query=null) {
    const fqdn = getFQDN("generate/questions");
    const body = { segments: segments };

    if (query) {
        body.query = query;
    }

    const result = await axios.post(
      fqdn,
      body,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  }