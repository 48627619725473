import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import SourcesInvolvedView from "./SourcesInvolvedView";
import OtherFilesView from "./OtherFilesView";
import Datasets from "../datasets";

import PanelCard from "../../components/PanelCard";

export default function FilesView() {
  return (
    <PanelCard height={"85vh"}>
      <Grid direction={"column"}>
        <Grid>
          <SourcesInvolvedView />
        </Grid>
        <Grid>
          <OtherFilesView />
        </Grid>
        <Grid>
          <Datasets />
        </Grid>
      </Grid>
    </PanelCard>
  );
}
