import { Box, Modal, Typography } from "@mui/material";
import { useSearchDispatch, useSearchState } from "../providers/SearchProvider";
import { style } from "./style";

export default function SummarizedTextModalView() {
  const { summarizedText, summarizationModalOpen } = useSearchState();

  const { handleSummarizeTextClose } = useSearchDispatch();

  return (
    <Modal
      open={summarizationModalOpen}
      onClose={handleSummarizeTextClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Summarized Text
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {summarizedText}
        </Typography>
      </Box>
    </Modal>
  );
}
