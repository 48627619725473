import React, { useState } from 'react';

import { Link, Typography, TextField, IconButton, Box, Button, Modal, useTheme, Icon } from "@mui/material";
import { Check, Clear, Cancel, Delete } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import FolioDetailProvider, {
  useFolioDetailState,
  useFolioDetailDispatch,
} from "./providers/FolioDetailProvider";
import PanelCard from "./components/PanelCard";

function StyledBox({ children }) {
  const theme = useTheme();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    backgroundColor: "background.default",
    border: 2,
    borderColor: "border.light",
    boxShadow: 24,
    borderRadius: 1,
    p: 4,
  };

  return <Box sx={style} children={children} />;
}

function GeneratedResearchQuestionsModal() {
  const { generatedResearchQuestionsModalOpen, generatedFollowUpQuestions } =
    useFolioDetailState();
  const { handleCloseGeneratedResearchQuestionsModal } =
    useFolioDetailDispatch();
  return (
    <Modal
      open={generatedResearchQuestionsModalOpen}
      onClose={handleCloseGeneratedResearchQuestionsModal}
    >
      <StyledBox>
        <Typography>Generated Questions</Typography>

        {generatedFollowUpQuestions.map((question) => (
          <Typography>{question}</Typography>
        ))}
      </StyledBox>
    </Modal>
  );
}

function SynthesizedEssayModal() {
  const { synthesizedEssayModalOpen, synthesizedEssay } = useFolioDetailState();
  const { handleCloseSynthesizedEssayModal } = useFolioDetailDispatch();
  return (
    <Modal
      open={synthesizedEssayModalOpen}
      onClose={handleCloseSynthesizedEssayModal}
    >
      <StyledBox>
        <Typography>Synthesized Essay</Typography>
        <Typography>{synthesizedEssay}</Typography>
      </StyledBox>
    </Modal>
  );
}

function ClipViewPage() {
  const { clipResponse } = useFolioDetailState();
  const { editFolioName,
          deleteFolio,
          generateFollowUpQuestionsFromSelectedClips,
          synthesizeEssay,
          deleteSelectedClips
        } = useFolioDetailDispatch();

  return (
    <Grid container direction="column" mt={3}>
      <GeneratedResearchQuestionsModal />
      <SynthesizedEssayModal />
      <Grid>
        <ReturnButton />
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <EditableTitle title={clipResponse.folio_name} onConfirm={editFolioName}/>
        </Grid>
        <Grid item>
          <IconButton onClick={deleteFolio}>
            <Delete/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={2.5}>
          <PanelCard minHeight="70vh">
            <Grid direction="column">
              <Grid>
                <Typography variant="h5">Clips Sources</Typography>
              </Grid>
            </Grid>
          </PanelCard>
        </Grid>
        <Grid item xs={7}>
          <PanelCard minHeight="70vh">
            <Grid direction="column">
              <Grid>
                <Typography variant="h5">List of Clips</Typography>
              </Grid>
              {clipResponse.clips.clips.map((clip) => (
                <ClipListItem key={clip.id} clip={clip} />
              ))}
            </Grid>
          </PanelCard>
        </Grid>
        <Grid item xs={2.5}>
          <PanelCard minHeight="70vh">
            <Grid direction="column">
              <Grid>
                <Typography variant="h5">
                  Selected Clips Options Menu
                </Typography>
              </Grid>
              <Grid>
                <Button onClick={() => synthesizeEssay()}>
                  <Typography fontWeight={"600"}>
                    Synthesize Selected Segments
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button>
                  <Typography fontWeight={"600"}>
                    Summarize Selected Segments
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button>
                  <Typography fontWeight={"600"}>
                    Export Selected Segments
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={() => deleteSelectedClips()}>
                  <Typography fontWeight={"600"}>
                    Delete Selected Segments
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <Button>
                  <Typography fontWeight={"600"}>Copy to New Folio</Typography>
                </Button>
              </Grid>
              <Grid>
                <Button
                  onClick={() => generateFollowUpQuestionsFromSelectedClips()}
                >
                  <Typography fontWeight={"600"}>
                    Generate research questions from selections
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </PanelCard>
        </Grid>
      </Grid>
    </Grid>
  );
}

function EditableTitle({ title, onConfirm }) {
  const [isEditing, setIsEditing] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  const handleTitleClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (event) => {
    setNewTitle(event.target.value);
  };

  const handleConfirmClick = () => {
    onConfirm(newTitle);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setNewTitle(title);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <>
          <TextField
            value={newTitle}
            onChange={handleTitleChange}
            autoFocus
          />
          <IconButton onClick={handleConfirmClick}>
            <Check />
          </IconButton>
          <IconButton onClick={handleCancelClick}>
            <Clear />
          </IconButton>
        </>
      ) : (
        <div onClick={handleTitleClick}>
          <Typography variant="h4" component="h1" gutterBottom>
            {title}
          </Typography>
        </div>
      )}
    </>
  );
}

function ClipListItem({ clip }) {
  const { selectedClips } = useFolioDetailState();
  const { selectClip } = useFolioDetailDispatch();

  const theme = useTheme();
  const border = selectedClips.includes(clip.id) ? `1px solid ${theme.palette.border.light}` : "none";

  return (
    <Grid>
      <Box onClick={() => selectClip(clip.id)} border={border} p={1} borderRadius={1}>
        <Typography>{clip.text}</Typography>
      </Box>
    </Grid>
  );
}

function ClipView() {
  return (
    <FolioDetailProvider>
      <ClipViewPage />
    </FolioDetailProvider>
  );
}

const ReturnButton = () => {
  const theme = useTheme();

  return (
    <Link href="/clips">
      <Cancel
        sx={{ ":hover": { backgroundColor: "theme.pallette.border.light" }, borderRadius: 15 }}
      />
    </Link>
  );
};

export default ClipView;
