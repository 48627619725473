import errorImage from "./confusedfox.jpeg";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const ErrorComponent = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent={"center"}
      alignItems={"center"}
      width="100vw"
      height={"100vh"}
      spacing={4}
    >
      <Grid>
        <Typography variant="h2">Something went wrong</Typography>
      </Grid>
      <Grid>
        <Typography>Please go back to the root</Typography>
      </Grid>
      <Grid>
        <img src={errorImage} alt="error" />
      </Grid>
    </Grid>
  );
};

export default ErrorComponent;
