import { useState, useMemo, createContext, useContext } from "react";

import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { track } from "@amplitude/analytics-browser";

import { getDocuments, createQuery } from "../services/SearchService";
import { generateFollowUpQuestions } from "../services/GenerationService";
import { useAuthState } from "./AuthProvider";
import { getFQDN } from "../utils/api";
import { useToast } from "./ToastProvider";
import { useClipDispatch } from "./ClipProvider";

const SearchProviderState = createContext();
const SearchProviderDispatch = createContext();

const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const { authToken } = useAuthState();
  const { refetchFolios } = useClipDispatch();

  const { openErrorToast, openSuccessToast } = useToast();

  const { data: searchResultsResponse, isLoading: searchResultsLoading } =
    useQuery(
      ["query-search", searchQuery, selectedDatasets],
      () => {
        const response = createQuery(searchQuery, selectedDatasets, authToken);
        return response;
      },
      {
        onSuccess: (data) => {
          console.log(data);
          const segments = [data.generated_response];
          generateFollowUpQuestionsMutation({
            segments: segments,
            token: authToken,
            query: data.query,
          });
        },
      },
      {
        enabled: searchQuery !== "" && !!authToken,
      }
    );

  const {
    mutate: generateFollowUpQuestionsMutation,
    isLoading: generateFollowUpQuestionsLoading,
  } = useMutation({
    mutationFn: async (params) => {
      const response = await generateFollowUpQuestions(
        params.segments,
        params.token,
        params.query
      );
      return response;
    },
    onSuccess: (response) => {
      console.log(response);
      setFollowUpQuestions(response.questions);

      // set the follow up questions state here.
    },
  });

  const searchResults = searchResultsResponse ?? {
    intent: "",
    query: "",
    generated_response: "",
    results: [],
  };

  const { data: totalDocsResponse, refetch: refetchDocs } = useQuery(
    "documents",
    async () => {
      const response = await getDocuments(authToken);

      return response.documents;
    },
    {
      enabled: !!authToken,
    }
  );
  async function getSummarizedText(text, token) {
    track("summarize", { text: text, length: text.length });
    const fqdn = getFQDN("summarize");
    let result = await axios.post(
      fqdn,
      {
        text: text,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  }

  const handleSummarizeTextOpen = (textToSumamrize) => {
    getSummarizedText(textToSumamrize, authToken).then((result) => {
      setSummarizationModalOpen(true);
      setSummarizedText(result.summarized_text);
    });
  };

  const handleSummarizeTextClose = () => {
    setSummarizedText("Loading...");
    setSummarizationModalOpen(false);
  };

  const { mutate: uploadDocument, isLoading: uploadDocumentLoading } =
    useMutation({
      mutationFn: async (params) => {
        const formData = new FormData();
        formData.append("pdfDocument", params.file);
        track("upload", { file: params.file.name });
        const fqdn = getFQDN("documents");
        const response = await axios.post(fqdn, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
          },
        });
        return response.data;
      },
      onSuccess: (response) => {
        openSuccessToast("Document successfully uploaded");
        refetchDocs();
      },
      onError: (e) => {
        if (e.response.status === 409) {
          openErrorToast(
            "Document could not be uploaded - Document has already been uploaded"
          );
        } else {
          openErrorToast("Document could not be uploaded");
        }
      },
    });

  const { mutate: addClipToFolio } = useMutation({
    mutationFn: async (params) => {
      const fqdn = getFQDN("clips");
      track("addClipToFolio", {
        folio_id: params.folioID,
        segment_id: params.segmentID,
      });
      const response = await axios.post(
        fqdn,
        { segment_id: params.segmentID, folio_id: params.folioID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: (response) => {
      openSuccessToast("Clip added to folio");
      setClipSegmentModalOpen(false);
      refetchFolios();
    },
    onError: (e) => {
      console.error(e);
      openErrorToast("Clip could not be added to folio");
    },
  });

  const [summarizedText, setSummarizedText] = useState("Loading...");

  const [selectedText, setSelectedText] = useState({});

  const [scrollToPage, setScrollToPage] = useState();
  const [summarizationModalOpen, setSummarizationModalOpen] = useState(false);
  const [clipSegmentModalOpen, setClipSegmentModalOpen] = useState(false);

  const handleClipSegmentModalOpen = () => {
    setClipSegmentModalOpen(true);
  };
  const handleClipSegmentModalClose = () => {
    setClipSegmentModalOpen(false);
    // todo set state of the staged information to null
  };

  const selectText = (resultObject) => {
    setSelectedText(resultObject);
  };
  const deselectSelection = () => {
    setSelectedText({});
  };

  const totalDocs = useMemo(() => {
    return totalDocsResponse ?? [];
  }, [totalDocsResponse]);

  const state = useMemo(
    () => ({
      clipSegmentModalOpen,
      searchResults,
      selectedText,
      summarizationModalOpen,
      summarizedText,
      totalDocs,
      uploadDocumentLoading,
      searchResultsLoading,
      scrollToPage,
      selectedDatasets,
      generateFollowUpQuestionsLoading,
      followUpQuestions,
      searchQuery,
    }),
    [
      clipSegmentModalOpen,
      searchResults,
      selectedText,
      summarizationModalOpen,
      summarizedText,
      totalDocs,
      uploadDocumentLoading,
      searchResultsLoading,
      scrollToPage,
      selectedDatasets,
      generateFollowUpQuestionsLoading,
      searchQuery,
    ]
  );

  const dispatch = useMemo(
    () => ({
      deselectSelection,
      setSearchQuery,
      getSummarizedText,
      handleClipSegmentModalClose,
      handleClipSegmentModalOpen,
      handleSummarizeTextClose,
      handleSummarizeTextOpen,
      selectText,
      setClipSegmentModalOpen,
      setSummarizationModalOpen,
      uploadDocument,
      addClipToFolio,
      setScrollToPage,
      setSelectedDatasets,
    }),
    [
      authToken,
      setSearchQuery,
      uploadDocument,
      getSummarizedText,
      addClipToFolio,
      setScrollToPage,
      setSelectedDatasets,
    ]
  );

  return (
    <SearchProviderState.Provider value={state}>
      <SearchProviderDispatch.Provider value={dispatch}>
        {children}
      </SearchProviderDispatch.Provider>
    </SearchProviderState.Provider>
  );
};

export default SearchProvider;
export const useSearchState = () => useContext(SearchProviderState);
export const useSearchDispatch = () => useContext(SearchProviderDispatch);
