import { useState, useContext } from "react";
import axios from "axios";

import { useAuthState } from "./providers/AuthProvider";
import { getFQDN } from "./utils/api";

import {
  Grid,
  Typography,
  Snackbar,
  Button,
  Alert,
  TextField,
} from "@mui/material";

import { useForm } from "react-hook-form";

function FeedbackView() {
  const { authToken } = useAuthState();

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    axios
      .post(getFQDN("feedback"), data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setOpen(true);
          setValue("feedbackBody", "");
        }
      });
  };

  return (
    <Grid container direction="column" alignItems={"center"} mt={0} spacing={5}>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert severity="success" onClose={handleClose} sx={{ width: "100%" }}>
          Your feedback has been submitted!
        </Alert>
      </Snackbar>
      <Grid item>
        <Typography variant="h2">Feedback</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4">Comments? Questions? Suggestions?</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4">Drop us a line.</Typography>
      </Grid>
      <Grid item>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" alignItems={"center"} spacing={5}>
            <Grid item minWidth={550}>
              <TextField
                id="outlined-multiline-static"
                label="Feedback"
                placeholder="Tell us your secrets... I mean thoughts"
                multiline
                rows={6}
                fullWidth
                {...register("feedbackBody", { required: true })}
              />
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default FeedbackView;
