import { Typography, Button, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";

import { useUserDispatch } from "../providers/UserStateProvider";
import { CreateOrganization } from "../services/OrganizationService";

export default function OrganizationRequiredView() {
  const { register, handleSubmit } = useForm();
  const { getAccessTokenSilently } = useAuth0();

  const { refetchUserState } = useUserDispatch();
  const handleContinue = async (data) => {
    const token = await getAccessTokenSilently();
    CreateOrganization(data.organizationName, token, refetchUserState);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      minHeight="100vh"
    >
      <Grid>
        <Typography variant="h2">We're glad you're here.</Typography>
      </Grid>
      <Grid>
        <Typography variant="h4">
          Please enter your organization's name
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(handleContinue)}>
        <Grid container direction={"column"} alignItems="center">
          <Grid>
            <TextField
              id="organization-name"
              label="Organization Name"
              variant="outlined"
              {...register("organizationName")}
            />
          </Grid>
          <Grid>
            <Button type="submit" variant="contained">
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}
