import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Box, Typography, Button, TextField, Grid } from "@mui/material";
import { ExpandMore, ExpandLess, Watch } from "@mui/icons-material";

import { useClipState, useClipDispatch } from "../../providers/ClipProvider";
import {
  useSearchDispatch,
  useSearchState,
} from "../../providers/SearchProvider";
import { style } from "../style";
import { create } from "@mui/material/styles/createTransitions";

export default function ClipSegmentModalAddFolio({ onClose }) {
  const [inFlight, setInFlight] = useState(false);
  const { clipSegmentModalOpen, selectedText } = useSearchState();
  const { handleClipSegmentModalClose, addClipToFolio } = useSearchDispatch();
  const { folios, isFolioMutationLoading } = useClipState();
  const { createFolioMutation } = useClipDispatch();

  const [isAddingFolio, setIsAddingFolio] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = (data) => {
    createFolioMutation({
      folioTitle: data.folioTitle,
      folioDescription: data.folioDescription,
    });
    // TODO should expose this mutation directly so we can handle
    // onSuccess / onError at the component level
    // and wait till mutation completes before closing
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item>
          <TextField
            label="Folio title"
            required={true}
            {...(errors.folioTitle && {
              error: true,
              helperText: "Required",
            })}
            {...register("folioTitle", { required: true })}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <TextField
            label="Description"
            {...register("folioDescription")}
            variant="standard"
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            disabled={isFolioMutationLoading || !watch("folioTitle")}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
