import { Box, Button, Link, Modal, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useClipState, useClipDispatch } from "./providers/ClipProvider";
import { useForm } from "react-hook-form";

import { createTheme } from "@mui/material/styles";

import theme from "./themes/theme"; // Import your theme object

const myTheme = createTheme(theme);

export default function FolioView() {
  const { folios } = useClipState();
  const { handleFolioCreationModalOpen } = useClipDispatch();
  return (
    <Grid container direction="column" minHeight="100vh">
      <FolioCreationModal />
      <Grid>
        <Button
          sx={{
            border: "1px solid",
            borderColor: "border.light",
            borderRadius: 2,
            m: 2,
          }}
          onClick={() => handleFolioCreationModalOpen()}
        >
          <Typography variant="h6">+ Create new Folio</Typography>
        </Button>
      </Grid>
      {folios.map((folio) => (
        <Grid key={folio.id}>
          <Link href={`/clips/${folio.id}`} sx={{ textDecoration: "none" }}>
            <Box
              sx={{
                border: "1px solid",
                borderColor: "border.light",
                borderRadius: 1,
                p: 2,
                m: 2,
                ":hover": {
                  backgroundColor: "primary.light",
                },
              }}
            >
              <Typography variant="h6">{folio.folio_name}</Typography>
              <Typography variant="body1">{folio.folio_description}</Typography>
              <Typography> Total Clips: {folio.clips_count}</Typography>
            </Box>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: `${theme.palette.background.light}`,
  border: `2px solid ${theme.palette.border.light}`,
  borderColor: `${theme.palette.border.light}`,
  boxShadow: 24,
  borderRadius: 1,
  p: 4,
};

const FolioCreationModal = () => {
  const { folioCreationModalOpen, isFolioMutationLoading } = useClipState();
  const { handleFolioCreationModalClose, createFolioMutation } =
    useClipDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    createFolioMutation(data);
  };
  return (
    <Modal
      open={folioCreationModalOpen}
      onClose={handleFolioCreationModalClose}
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" spacing={2}>
            <Grid>
              <Typography variant="h6">Create new Folio</Typography>
            </Grid>
            <Grid>
              <Typography variant="body2">
                Folios are collections of clips. You can create a folio for a
                particular project, or for a particular theme.
              </Typography>
            </Grid>
            <Grid>
              <TextField
                label="Folio title"
                required={true}
                {...(errors.folioTitle && {
                  error: true,
                  helperText: "Required",
                })}
                {...register("folioTitle", { required: true })}
                variant="standard"
                sx={{ width: 500 }}
              />
            </Grid>
            <Grid>
              <TextField
                sx={{ width: 500 }}
                label="Description"
                {...register("folioDescription")}
                variant="standard"
              />
            </Grid>
            <Grid mt={3}>
              <Button
                variant="contained"
                size="small"
                type="submit"
                disabled={isFolioMutationLoading}
              >
                <Typography variant="h6">Create folio</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
