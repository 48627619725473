import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import PDFModalView from "../../modals/PDFModalView";
import ClipSegmentModal from "../../modals/ClipSegmentModal";
import SummarizedTextModalView from "../../modals/SummarizedModalView";
import FilesView from "../files/FilesView";
import SearchResults from "./SearchResults";

export default function SearchResultsView() {
  return (
    <>
      <PDFModalView />
      <SummarizedTextModalView />
      <ClipSegmentModal />
      <Grid
        container
        direction="row"
        minHeight="100vh"
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        spacing={4}
        py={3}
      >
        <Grid xs={4} position="sticky" top={0}>
          <FilesView />
        </Grid>
        <Grid xs={8}>
          <SearchResults />
        </Grid>
      </Grid>
    </>
  );
}
