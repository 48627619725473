import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import DescriptionIcon from "@mui/icons-material/Description";

export default function SourceRow({ border, onClick, text, disabled }) {
  return (
    <Grid p={0}>
      <Box
        border={border}
        borderRadius={1}
        display="flex"
        onClick={onClick}
        width="19rem"
        p={1}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: !disabled && "pointer",
        }}
      >
        <DescriptionIcon sx={{ color: "#527EE7" }} />
        <Typography ml={1} noWrap>
          {text}
        </Typography>
      </Box>
    </Grid>
  );
}
