import { useState } from "react";
import { AppBar, Typography, Menu, MenuItem } from "@mui/material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useAuth0 } from "@auth0/auth0-react";

import { AccountCircle } from "@mui/icons-material";
import config from "../config/Config";
import NavSearchBar from "./NavSearchBar";

export default function AuthorizedTopNav() {
  return (
    <AppBar
      component="nav"
      position="relative"
      sx={{
        boxShadow: "none",
        zIndex: 1,
        height: "85px",
        backgroundColor: "white",
      }}
      py={1}
    >
      <Grid
        container
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        height="100%"
        px={5}
      >
        <Grid>
          <LeftSideThings />
        </Grid>
        <Grid>
          <NavigationLinks />
        </Grid>
      </Grid>
    </AppBar>
  );
}

const LeftSideThings = () => {
  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid>
        <TopNavCompanyName />
      </Grid>
      <Grid>
        <NavSearchBar />
      </Grid>
    </Grid>
  );
};

const linksStyle = {
  fontSize: 16,
  fontWeight: 600,
  color: "#353227",
};
function NavLink(props) {
  return (
    <Link to={props.to} style={{ textDecoration: "none" }}>
      <Typography sx={linksStyle}>{props.name}</Typography>
    </Link>
  );
}

function NavigationLinks() {
  return (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid>
        <NavLink to="/feedback" name="Feedback" />
      </Grid>
      <Grid>
        <NavLink to="/" name="reSearch" />
      </Grid>
      <Grid>
        <NavLink to="/clips" name="Clips" />
      </Grid>
      <Grid>
        <MenuButton />
      </Grid>
    </Grid>
  );
}

function MenuButton() {
  const [anchorElement, setAnchorElement] = useState(null);
  const open = Boolean(anchorElement);
  const handleClick = (event) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };
  const { logout } = useAuth0();
  return (
    <>
      <AccountCircle sx={{ color: "#9B9898" }} onClick={handleClick} />
      <Menu
        anchorEl={anchorElement}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem>Profile</MenuItem>
        <MenuItem onClick={() => logout({ returnTo: config.frontend.host })}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

function TopNavCompanyName() {
  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <Typography
        sx={{
          color: "primary.main",
        }}
        fontSize={24}
        fontWeight="600"
      >
        Uncommon Knowledge
      </Typography>
    </Link>
  );
}
