import { Button, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

export default function SegmentActionButton({ icon, action, title }) {
  return (
    <Grid>
      <Tooltip title={title}>
        <Button sx={{ color: "#527EE7" }} size="small" onClick={action}>
          {icon}
        </Button>
      </Tooltip>
    </Grid>
  );
}
