import stagingConfig from "./Staging.js";
import productionConfig from "./Production.js";
import devConfig from "./Dev.js";

function getConfigData() {
  let env = process.env.REACT_APP_ENV;

  switch (env) {
    case "production":
      return productionConfig;
    case "staging":
      return stagingConfig;
    default:
      return devConfig;
  }
}

const configData = getConfigData();
export default configData;
