import { useState } from "react";
import { TextField, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import SearchIcon from "@mui/icons-material/Search";
import { track } from "@amplitude/analytics-browser";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import { useSearchState, useSearchDispatch } from "../providers/SearchProvider";
import DatasetSelect from "./DatasetSelect";

export default function NavSearchBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedDatasets, setSelectedDatasets] = useState([]);
  const { searchResults, searchResultsLoading } = useSearchState();
  const { setSearchQuery } = useSearchDispatch();
  const { register, handleSubmit, onChange } = useForm();
  const onSubmit = (data) => {
    track("search", { query: data.queryField });
    setSearchQuery(data.queryField);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid>
          <DatasetSelect />
        </Grid>
        <Grid>
          <TextField
            variant="standard"
            sx={{ width: "300px" }}
            placeholder={
              searchResults.query != "" ? searchResults.query : "Search"
            }
            {...register("queryField", {
              required: true,
              onChange: () => {
                if (pathname !== "/") {
                  navigate("/");
                }
              },
            })}
            InputProps={{
              endAdornment: (
                <Button disabled={searchResultsLoading} type="submit">
                  <SearchIcon />
                </Button>
              ),
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
}
