import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDocumentQueryState } from "../../providers/DocumentQueryProvider";

export default function DocumentQAAnswer() {
  const { answer, supportingEvidence } = useDocumentQueryState();

  return (
    <>
      <Typography>{answer}</Typography>
      {supportingEvidence.length > 0 ? (
        <Accordion>
          <AccordionSummary
            style={{ padding: 0 }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography fontWeight="bold">Supporting Evidence</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Typography>
              {supportingEvidence.map((summary) => (
                <Box
                  sx={{
                    border: "1px solid black",
                    marginBottom: "10px",
                    padding: "5px",
                  }}
                >
                  <Typography>{summary}</Typography>
                </Box>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ) : null}
    </>
  );
}
