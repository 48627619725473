import { useState, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import { useSearchState } from "../../providers/SearchProvider";
import { useAuthState } from "../../providers/AuthProvider";
import { getPDFData } from "../../services/SearchService";
import { useDocumentQueryState } from "../../providers/DocumentQueryProvider";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export default function PDFDocument() {
  const [numPages, setNumPages] = useState(1);
  const { modalOpen, scrollToPage } = useSearchState();
  const { authToken } = useAuthState();
  const { pdfDocumentID } = useDocumentQueryState();

  const [pdfData, setPDFData] = useState("");
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleScroll = () => {
    document
      .getElementById(`pdf-current-page-${scrollToPage}`)
      .scrollIntoView();
  };

  useEffect(() => {
    if (pdfDocumentID === null) {
      return;
    }
    async function doTheThing(document_id, authToken) {
      const result = await getPDFData(document_id, authToken);
      setPDFData(result.document_data);
    }
    doTheThing(pdfDocumentID, authToken);
  }, [modalOpen]);

  return (
    <Document
      file={"data:application/pdf;base64," + pdfData}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (_el, index) => (
        <div id={`pdf-current-page-${index}`} key={`pdf-current-page-${index}`}>
          <Page
            id={`pdf-current-page-${index}`}
            onLoadSuccess={() => {
              scrollToPage && index === numPages - 1 && handleScroll();
            }}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        </div>
      ))}
    </Document>
  );
}
