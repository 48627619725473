import React, { useState } from "react";
import { Select, MenuItem, Box, OutlinedInput } from "@mui/material";

import { useOrgState } from "../providers/OrganizationProvider";
import { useSearchState, useSearchDispatch } from "../providers/SearchProvider";

export default function MultipleSelectChip() {
  const { datasets } = useOrgState();

  const { selectedDatasets } = useSearchState();
  const { setSelectedDatasets } = useSearchDispatch();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedDatasets(value);
  };
  return (
    <Select
      multiple
      value={selectedDatasets}
      onChange={handleChange}
      input={<OutlinedInput id="select-multiple" />}
      displayEmpty
      renderValue={(value) => (
        <Box>
          {`Searching ${value.length === 0 ? "all" : value.length} ${
            value.length === 1 ? "dataset" : "datasets"
          }`}
        </Box>
      )}
    >
      {datasets?.map(({ name, id }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
}
