import { useState } from "react";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import {
  useDocumentQueryDispatch,
  useDocumentQueryState,
} from "../../providers/DocumentQueryProvider";
import DocumentQABox from "./DocumentQABox";

export default function DocumentQuery() {
  const {
    documentName,
    documentSummary,
    suggestedQueries,
    documentDetailsLoading,
    documentQuery,
    questionsAnswersLoading,
    supportingEvidence,
  } = useDocumentQueryState();

  const { queryDocument } = useDocumentQueryDispatch();

  const [queryValue, setQueryValue] = useState("");

  const handleQueryValueChange = (event) => {
    setQueryValue(event.target.value);
  };

  return (
    <Box
      sx={{
        width: "40vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderBottom: "1px solid grey",
        }}
      >
        {!documentDetailsLoading ? (
          <>
            <Typography sx={{ mb: "20px" }} fontSize={24} fontWeight="bold">
              {documentName}
            </Typography>
            <Typography>{documentSummary}</Typography>
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>

      {suggestedQueries.length > 0 ? (
        <Box
          sx={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ mb: "20px" }} fontWeight="bold">
            Suggested Queries
          </Typography>
          <Box sx={{ display: "flex" }}>
            {suggestedQueries
              .filter((suggestedQuery) => suggestedQuery !== documentQuery)
              .map((suggestedQuery) => (
                <Button
                  onClick={() => queryDocument({ query: suggestedQuery })}
                  variant="outlined"
                  sx={{
                    margin: "5px",
                    padding: "5px",
                  }}
                >
                  {suggestedQuery}
                </Button>
              ))}
          </Box>
        </Box>
      ) : null}

      <DocumentQABox />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          fullWidth
          multiline
          placeholder="Query"
          value={queryValue}
          onChange={handleQueryValueChange}
          sx={{ mr: "10px" }}
        />
        <Button
          disabled={questionsAnswersLoading}
          variant="contained"
          onClick={() => {
            queryDocument({ query: queryValue });
            setQueryValue("");
          }}
        >
          Send
        </Button>
      </Box>
    </Box>
  );
}
