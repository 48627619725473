const stagingConfig = {
  auth: {
    domain: "carburetor-dev.us.auth0.com",
    clientId: "Mg6ljivxZKYR18ELUsvzJjUOnoI3KJc6",
    audience: "https://carburetor-dev.us.auth0.com/api/v2/",
  },
  backend: {
    host: "https://lionfish-app-bkzik.ondigitalocean.app/",
    api: "https://lionfish-app-bkzik.ondigitalocean.app/api/v1/",
  },
  frontend: {
    host: "https://knowledge.uncommon.industries",
  },
};
export default stagingConfig;
