import { Modal, Box } from "@mui/material";

import { useSearchDispatch } from "../providers/SearchProvider";
import {
  useDocumentQueryDispatch,
  useDocumentQueryState,
} from "../providers/DocumentQueryProvider";
import { style } from "./style";
import PDFDocument from "./components/PDFDocument";
import DocumentQuery from "./components/DocumentQuery";

export default function PDFModalView() {
  const { modalOpen } = useDocumentQueryState();
  const { setScrollToPage } = useSearchDispatch();

  const { handleModalClose } = useDocumentQueryDispatch();

  const handleClose = () => {
    handleModalClose();
    setScrollToPage(undefined);
  };
  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Box
        sx={style}
        overflow="scroll"
        position="absolute"
        height="100%"
        display="flex"
      >
        <PDFDocument />
        <DocumentQuery />
      </Box>
    </Modal>
  );
}
