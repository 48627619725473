import axios from "axios";

import { getFQDN } from "../utils/api.js";
export const getDocuments = async (authToken) => {
  try {
    let fqdn = getFQDN("documents");
    const url = fqdn;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createQuery = async (query, datasets, authToken) => {
  console.log("query", query);
  var params = new URLSearchParams();
  params.append("query", query);
  if (datasets.length > 0) {
    datasets.forEach((dataset) => {
      params.append("filtered_datasets", dataset);
    });
  }
  try {
    let fqdn = getFQDN("search");

    const response = await axios.get(fqdn, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = response.data;

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getSummarizedText = async (text) => {
  try {
    const fqdn = getFQDN("summarize");

    const response = await axios.post(fqdn, {
      text: text,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getPDFData = async (selectedDocumentID, authToken) => {
  try {
    const fqdn = getFQDN(`documents/${selectedDocumentID}`);

    const response = await axios.get(fqdn, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
