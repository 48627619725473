import { Box, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { useDocumentQueryDispatch } from "../../../../providers/DocumentQueryProvider";

export default function SourceDocumentView({ result }) {
  const { handleModalOpen } = useDocumentQueryDispatch();

  console.log("HERE I AM");

  return (
    <Box p={1} borderRadius={1}>
      <Grid container>
        <Link
          component="button"
          onClick={() => handleModalOpen(result.document_id)}
        >
          <Typography fontWeight="bold">{result.document_file_name}</Typography>
        </Link>
      </Grid>
    </Box>
  );
}
