import { Box, Typography, CircularProgress } from "@mui/material";
import { useDocumentQueryState } from "../../providers/DocumentQueryProvider";
import DocumentQAAnswer from "./DocumentQAAnswer";

export default function DocumentQABox() {
  const { documentQuery, questionsAnswersLoading } = useDocumentQueryState();

  const query = documentQuery ?? "";

  return (
    <Box
      sx={{
        my: "20px",
        border: "1px solid black",
        padding: "5px",
        overflow: "scroll",
      }}
    >
      <Typography sx={{ mb: "5px" }} fontWeight="bold">{`Question: ${
        query ?? ""
      }`}</Typography>

      {questionsAnswersLoading ? <CircularProgress /> : <DocumentQAAnswer />}
    </Box>
  );
}
