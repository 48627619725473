import { createContext, useContext, useMemo } from "react";
import { useQuery } from "react-query";

import { useAuthState } from "./AuthProvider";
import { useUserState } from "./UserStateProvider";
import { getOrganization } from "../services/OrganizationService";

const OrgProviderState = createContext();

const OrgProvider = ({ children }) => {
  const { authToken } = useAuthState();
  const { userState } = useUserState();

  const { data: organizationResponse } = useQuery(
    ["organization", userState?.organization_id],
    async () =>
      await getOrganization({ orgId: userState?.organization_id }, authToken),
    { enabled: !!userState?.organization_id && !!authToken }
  );

  const organization = organizationResponse?.data?.data ?? {};

  const { datasets, description, id, name, users } = organization;

  const state = useMemo(
    () => ({
      datasets,
      description,
      id,
      name,
      users,
    }),
    [datasets, description, id, name, users]
  );

  return (
    <OrgProviderState.Provider value={state}>
      {children}
    </OrgProviderState.Provider>
  );
};

export default OrgProvider;
export const useOrgState = () => {
  const context = useContext(OrgProviderState);

  if (context === undefined) {
    throw new Error("useOrgState must be used within an OrgProvider");
  }
  return context;
};
