import { useMemo, useState, createContext, useContext } from "react";
import { useMutation, useQuery } from "react-query";

import { useParams } from "react-router-dom";
import axios from "axios";

import { getFolioData } from "../services/FolioService";
import { generateFollowUpQuestions } from "../services/GenerationService";
import { useAuthState } from "./AuthProvider";
import { getFQDN } from "../utils/api";
import { useToast } from "./ToastProvider";
import { useClipDispatch } from "./ClipProvider";

const FolioDetailProviderState = createContext();
const FolioDetailProviderDispatch = createContext();

export default function FolioDetailProvider({ children }) {
  const { authToken } = useAuthState();
  const { folioId } = useParams();
  const { refetchFolios } = useClipDispatch();

  const { openErrorToast, openSuccessToast } = useToast();

  const { data: clipResponseData, refetch: refetchClipData } = useQuery(
    ["clip-search"],
    () => {
      const response = getFolioData(folioId, authToken);
      return response;
    },
    {
      enabled: !!authToken,
    }
  );

  const clipResponse = clipResponseData?.data ?? {
    folio_name: "unknown!",
    clips: { clips: [] },
  };

  const [selectedClips, setSelectedClips] = useState([]);

  const [
    generatedResearchQuestionsModalOpen,
    setGeneratedResearchQuestionsModalOpen,
  ] = useState(false);
  const [synthesizedEssayModalOpen, setSynthesizedEssayModelOpen] =
    useState(false);

  const handleCloseSynthesizedEssayModal = () => {
    setSynthesizedEssayModelOpen(false);
  };

  const handleCloseGeneratedResearchQuestionsModal = () => {
    setGeneratedResearchQuestionsModalOpen(false);
  };

  const { mutate: editFolioName } = useMutation({
    mutationFn: async (newName) => {
      const fqdn = getFQDN(`folios/${folioId}`)

      const response = await axios.patch(
        fqdn,
        { folio_name: newName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      refetchFolios();
      refetchClipData();
    },
    onError: (e) => {
      console.error(e)
    },
  });

  const { mutate: deleteFolio } = useMutation({
    mutationFn: async () => {
      const fqdn = getFQDN(`folios/${folioId}`)

      const response = await axios.delete(
        fqdn,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      openSuccessToast("Folio successfully deleted");
      refetchFolios();
      // For some reason redirect("/clips"); doesn't work...
      window.location.assign("/clips");
    },
    onError: (e) => {
      openErrorToast("Folio could not be deleted");
      console.error(e)
    },
  });

  const deleteSelectedClips = async () => {
    selectedClips.forEach(async (clipId) => {
      try {
        let _ = await deleteClip(clipId, authToken);
        refetchClipData();
        console.log(`Clip with id ${clipId} deleted successfully.`);
      } catch (error) {
        console.error(`Failed to delete clip with id ${clipId}:`, error);
      }
    });

    setSelectedClips([]);
  };

  async function deleteClip(clipId, authToken) {
    const fqdn = getFQDN(`clips/${clipId}`);

    const response = await axios.delete(
      fqdn,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data
  }

  const generateFollowUpQuestionsFromSelectedClips = () => {
    setGeneratedResearchQuestionsModalOpen(true);

    const segments = selectedClips.map((clipId) => {
      const clip = clipResponse.clips.clips.find((clip) => clip.id === clipId);

      return clip.text;
    });
    generateFollowUpQuestions(segments, authToken).then((result) => {
      setGeneratedFollowUpQuestions(result.questions);
    });
  };

 

  const synthesizeEssay = () => {
    setSynthesizedEssayModelOpen(true);
    const segments = selectedClips.map((clipId) => {
      const clip = clipResponse.clips.clips.find((clip) => clip.id === clipId);
      return clip.text;
    });

    synthesizeSegmentsIntoEssay(segments, authToken).then((result) => {
      setSynthesizedEssay(result.essay);
    });
  };

  async function synthesizeSegmentsIntoEssay(segments, token) {
    const fqdn = getFQDN("generate/essay");

    let result = await axios.post(
      fqdn,
      {
        segments: segments,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data;
  }

  const [synthesizedEssay, setSynthesizedEssay] = useState("");

  const [generatedFollowUpQuestions, setGeneratedFollowUpQuestions] = useState(
    []
  );

  const selectClip = (clipId) => {
    if (selectedClips.includes(clipId)) {
      setSelectedClips(selectedClips.filter(id => id !== clipId));
    } else {
      setSelectedClips([...selectedClips, clipId]);
    }
  };

  const state = useMemo(
    () => ({
      clipResponse,
      selectedClips,
      generatedResearchQuestionsModalOpen,
      synthesizedEssayModalOpen,
      synthesizedEssay,
      generatedFollowUpQuestions,
    }),
    [
      clipResponse,
      selectedClips,
      generatedFollowUpQuestions,
      generatedResearchQuestionsModalOpen,
      synthesizedEssay,
      synthesizedEssayModalOpen,
    ]
  );
  const dispatch = useMemo(
    () => ({
      editFolioName,
      deleteFolio,
      selectClip,
      deleteSelectedClips,
      generateFollowUpQuestionsFromSelectedClips,
      handleCloseGeneratedResearchQuestionsModal,
      handleCloseSynthesizedEssayModal,
      synthesizeEssay,
    }),
    [generateFollowUpQuestionsFromSelectedClips, synthesizeEssay]
  );
  return (
    <FolioDetailProviderState.Provider value={state}>
      <FolioDetailProviderDispatch.Provider value={dispatch}>
        {children}
      </FolioDetailProviderDispatch.Provider>
    </FolioDetailProviderState.Provider>
  );
}

export const useFolioDetailState = () => useContext(FolioDetailProviderState);
export const useFolioDetailDispatch = () =>
  useContext(FolioDetailProviderDispatch);
