import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import { useSearchState } from "../../providers/SearchProvider";
import { useDocumentQueryDispatch } from "../../providers/DocumentQueryProvider";
import SourceRow from "../../components/SourceRow";

export default function SourcesInvolvedView() {
  const { searchResults } = useSearchState();
  const { handleModalOpen } = useDocumentQueryDispatch();
  const { selectedText } = useSearchState();

  const uniqueSources = searchResults.results.reduce((acc, result) => {
    const found = acc.find((item) => item.document_id === result.document_id);
    if (!found) {
      acc.push(result);
    }
    return acc;
  }, []);

  return (
    <Grid container direction="column" alignItems={"center"} p={1}>
      <Grid>
        <Typography fontSize={24} fontWeight={700} color="#28251A">
          Sources Involved
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        {[...uniqueSources].map((uniqueSource) => (
          <SourceRow
            key={uniqueSource.document_id}
            text={uniqueSource.document_file_name}
            onClick={() => {
              handleModalOpen(uniqueSource.document_id);
            }}
            border={
              selectedText.document_id === uniqueSource.document_id
                ? "2px solid #DCDCDC"
                : ""
            }
          />
        ))}
      </Grid>
    </Grid>
  );
}
