import axios from "axios";
import { getFQDN } from "../utils/api";

export const getUserState = async (authToken) => {
  try {
    const fqdn = getFQDN("user_state");
    const response = await axios.get(fqdn, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
