import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal, Box, Typography, Button, Grid } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import { useClipState } from "../../providers/ClipProvider";
import {
  useSearchDispatch,
  useSearchState,
} from "../../providers/SearchProvider";
import { style } from "../style";
import ClipSegmentModalAddFolio from "./ClipSegmentModalAddFolio";

export default function ClipSegmentModal() {
  const { clipSegmentModalOpen, selectedText } = useSearchState();
  const { handleClipSegmentModalClose, addClipToFolio } = useSearchDispatch();
  const { folios } = useClipState();

  const [selectedFolio, setSelectedFolio] = useState();
  const [isAddingFolio, setIsAddingFolio] = useState(false);

  useEffect(() => {
    if (folios.length > 0 && !selectedFolio) {
      setSelectedFolio(folios[0].id);
    }
  }, [folios, selectedFolio]);

  const handleAddFolioClick = () => {
    setIsAddingFolio(!isAddingFolio);
  };

  const handleFolioChange = (event) => {
    setSelectedFolio(event.target.value);
  };

  const { handleSubmit } = useForm();
  const handleClipSubmit = () => {
    addClipToFolio({ folioID: selectedFolio, segmentID: selectedText.segment_id });
  };

  return (
    <Modal open={clipSegmentModalOpen} onClose={handleClipSegmentModalClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          I am the clipSegmentModal
        </Typography>
        <Typography>{selectedText.segment_text}</Typography>
        <form onSubmit={handleSubmit(handleClipSubmit)}>
          <select
            value={selectedFolio}
            disabled={folios.length === 0}
            onChange={handleFolioChange}
          >
            {folios.map((folio) => (
              <option key={folio.id} value={folio.id}>
                {folio.folio_name}
              </option>
            ))}
          </select>

          <Button type="submit" disabled={folios.length === 0}>
            <Typography>Clip this section</Typography>
          </Button>
          <Button>
            <Grid container alignItems="center">
              <Button
                onClick={handleAddFolioClick}
              >
                {isAddingFolio ? <ExpandLess /> : <ExpandMore />}
                <Typography>Add Folio</Typography>
              </Button>
            </Grid>
          </Button>
        </form>
        {isAddingFolio && (
          <ClipSegmentModalAddFolio
            onClose={() => {
              setIsAddingFolio(false);
            }}
          />
        )}
      </Box>
    </Modal>
  );
}
