import axios from "axios";

import { getFQDN } from "../utils/api.js";

export const getDocumentDetails = async ({ id }, authToken) => {
  try {
    const fqdn = getFQDN(`documents/${id}/details`);
    const url = fqdn;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = response.data;
    return await data;
  } catch (error) {
    console.error(error);
  }
};

export const queryDocument = async ({ id, query }, authToken) => {
  try {
    const fqdn = getFQDN(`documents/${id}/qa`);
    const url = fqdn;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      params: {
        query,
      },
    });
    const data = response.data;
    return await data;
  } catch (error) {
    console.error(error);
  }
};
