import { createContext, useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";

import { useAuthState } from "./AuthProvider";
import { getUserState } from "../services/UserStateService";

const UserStateProviderState = createContext();
const UserStateProviderDispatch = createContext();

const UserStateProvider = ({ children }) => {
  const { authToken } = useAuthState();

  const { refetch: refetchUserState } = useQuery(
    "userState",
    async () => {
      const response = await getUserState(authToken);
      setUserState(response.data);
    },
    {
      enabled: !!authToken,
    }
  );

  const [userState, setUserState] = useState(null);

  const state = useMemo(
    () => ({
      userState: userState,
    }),
    [userState]
  );

  const dispatch = useMemo(
    () => ({
      refetchUserState: refetchUserState,
    }),
    [refetchUserState]
  );

  return (
    <UserStateProviderState.Provider value={state}>
      <UserStateProviderDispatch.Provider value={dispatch}>
        {children}
      </UserStateProviderDispatch.Provider>
    </UserStateProviderState.Provider>
  );
};

export default UserStateProvider;
export const useUserState = () => useContext(UserStateProviderState);
export const useUserDispatch = () => useContext(UserStateProviderDispatch);
