import axios from "axios";
import { getFQDN } from "../utils/api";

export const createFolio = async (folioTitle, folioDescription, authToken) => {
  try {
    const fqdn = getFQDN("folios");

    const response = await axios.post(
      fqdn,
      {
        folio_name: folioTitle,
        folio_description: folioDescription,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getFolios = async (authToken) => {
  try {
    const fqdn = getFQDN("folios");
    const response = await axios.get(fqdn, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getFolioData = async (folioId, authToken) => {
  try {
    const fqdn = getFQDN(`folios/${folioId}/clips`);

    const response = await axios.get(fqdn, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
