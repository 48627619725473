import { createContext, useContext, useState, useMemo } from "react";
import { useMutation, useQuery } from "react-query";
import { track } from "@amplitude/analytics-browser";

import { useAuthState } from "./AuthProvider";
import { createFolio, getFolios } from "../services/FolioService";
import { useToast } from "./ToastProvider";

const ClipProviderState = createContext();
const ClipProviderDispatch = createContext();

const ClipProvider = ({ children }) => {
  const { authToken } = useAuthState();

  const [folios, setFolios] = useState([]);
  const [folioCreationModalOpen, setFolioCreationModalOpen] = useState(false);

  const { openErrorToast, openSuccessToast } = useToast();

  const handleFolioCreationModalOpen = () => {
    setFolioCreationModalOpen(true);
  };
  const handleFolioCreationModalClose = () => {
    setFolioCreationModalOpen(false);
  };
  const { mutate: createFolioMutation, isLoading: isFolioMutationLoading } =
    useMutation({
      mutationFn: async (params) => {
        track("createFolio", { folioTitle: params.folioTitle });
        const response = await createFolio(
          params.folioTitle,
          params.folioDescription,
          authToken
        );
        return response;
      },
      onSuccess: (response) => {
        const title = response?.data?.data?.folio_name ?? "";
        refetchFolios();
        handleFolioCreationModalClose();
        openSuccessToast(`Successfully created ${title} folio`);
      },
      onError: (e) => {
        console.error(e);
        openErrorToast("Could not create folio");
      },
    });

  const { refetch: refetchFolios } = useQuery(
    "folios",
    async () => {
      const response = await getFolios(authToken);

      setFolios(response.data.folios);
    },
    {
      enabled: !!authToken,
    }
  );

  const state = useMemo(
    () => ({ folios, folioCreationModalOpen, isFolioMutationLoading }),
    [folios, folioCreationModalOpen, isFolioMutationLoading]
  );
  const dispatch = useMemo(
    () => ({
      handleFolioCreationModalOpen,
      handleFolioCreationModalClose,
      createFolioMutation,
      refetchFolios,
    }),
    [
      handleFolioCreationModalOpen,
      handleFolioCreationModalClose,
      createFolioMutation,
      refetchFolios,
    ]
  );
  return (
    <ClipProviderState.Provider value={state}>
      <ClipProviderDispatch.Provider value={dispatch}>
        {children}
      </ClipProviderDispatch.Provider>
    </ClipProviderState.Provider>
  );
};

export default ClipProvider;
export const useClipState = () => useContext(ClipProviderState);
export const useClipDispatch = () => useContext(ClipProviderDispatch);
