import { Box,Button,  Typography, Divider, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import fox from "./fox.svg";

import SourceSegmentView from "./components/source-segment-view";
import PanelCard from "../../components/PanelCard";
import { useSearchState, useSearchDispatch } from "../../providers/SearchProvider";
import SearchAnswerCard from "./SearchAnswerCard";
import SourceDocumentView from "./components/source-document-view";

export default function SearchResults() {
  const { searchResults,
          searchResultsLoading,
          generateFollowUpQuestionsLoading,
          followUpQuestions,
          
        } = useSearchState();
  const {setSearchQuery} = useSearchDispatch();
  if (searchResultsLoading) {
    return (
      <Grid sx={{ display: "flex", justifyContent: "center", mt: "10%" }}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <PanelCard minHeight={"85vh"}>
      <Grid container direction="column" spacing={2.5} p={2}>
        {!!searchResults.generated_response && (
          <SearchAnswerCard answer={searchResults.generated_response} />
        )}
        { generateFollowUpQuestionsLoading ? ( // generateFollowUpQuestionsLoading is not defined
          <Grid sx={{ display: "flex", justifyContent: "center", mt: "10%" }}>
            <CircularProgress />  
          </Grid>
        ) : (
          <Grid container direction="row" justifyContent={"space-evenly"} >
          {followUpQuestions.map((question) => (
              <Grid xs={3} sx={{border: 'solid 2px', borderRadius: 2,  borderColor:"#dddddd"}} >
                <Button onClick={()=>  setSearchQuery(question)}>
                <Typography variant="body2" color="#262626">{question}</Typography>
                </Button>
              </Grid>
          ))}
          </Grid>
        )}

        {searchResults.results.length !== 0 && (
          <>
            <Grid>
              <Typography variant="h5">Sources</Typography>
            </Grid>
            {searchResults.results ? (
              searchResults.results.slice(0, 10).map((result) => (
                <Grid
                  key={result.document_text}
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {result.result_type === "Segment" ? (
                    <SourceSegmentView result={result} />
                  ) : (
                    <SourceDocumentView result={result} />
                  )}
                  <Divider />
                </Grid>
              ))
            ) : (
              <Box
                sx={{ display: "flex", justifyContent: "center", mt: "10%" }}
              >
                <img src={fox} alt="fox" />
              </Box>
            )}
          </>
        )}
      </Grid>
    </PanelCard>
  );
}
