import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useOrgState } from "../../providers/OrganizationProvider";
import SourceView from "../../components/SourceRow";

export default function Datasets() {
  const { datasets } = useOrgState();

  return (
    <Grid container direction="column" alignItems={"center"} p={1}>
      <Grid>
        <Typography fontSize={24} fontWeight={700} color="#28251A">
          Datasets
        </Typography>
      </Grid>
      <Grid container direction="column" spacing={1}>
        <Box sx={{ overflow: "scroll" }} maxHeight="30vh" width="19rem">
          {datasets?.length > 0 &&
            [...datasets].map(({ name, id }) => (
              <SourceView key={id} text={name} disabled />
            ))}
        </Box>
      </Grid>
    </Grid>
  );
}
